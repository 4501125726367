import jwt_decode from 'jwt-decode'
import * as Sentry from '@sentry/react'

// import { bootIntercom, shutIntercom } from 'lib/intercom'
import { exchangeRefreshToken } from 'api/auth'
import { getApolloClient } from 'api/apollo'
import { getUserInfoAsync } from 'slices/user'
import { store } from 'app/store'
import { login as loginAction, logout as logoutAction } from 'slices/user'

import packageInfo from '../../package.json'

const LANG = 'en'

export type Decoded = {
  userId: string
  email?: string
  name?: string
  role?: string
  iat?: number
  exp?: number
}

export type User = {
  id: string
  name?: string
  email?: string
  role?: string
}

export enum RefreshStatus {
  pending = 'pending',
  completed = 'completed',
  error = 'error'
}

export const loginTokenKey = process.env.REACT_APP_LOGIN_TOKEN_KEY || 'monnchAccessToken'
export const refreshTokenKey = process.env.REACT_APP_LOGIN_REFRESH_TOKEN_KEY || 'monnchRefreshToken'
export const refreshStatusKey =
  process.env.REACT_APP_LOGIN_REFRESH_STATUS_KEY || 'monnchRefreshStatus'
export const restoreAuntCountKey =
  process.env.REACT_APP_LOGIN_REFRESH_STATUS_KEY || 'monnchRestoreAuntCountKey'

export const getUser = (): User | null | undefined=> {
  try {
    if (isAuthenticated()) {
      const token = getToken()
      const decoded = jwt_decode(token as string) as Decoded
      return {
        id: decoded.userId,
        email: decoded.email,
        name: decoded.name,
        role: decoded.role
      }
    }
    return null
  } catch (e) {
    console.log(e)
  }
}

export const getUserRole = () => {
  try {
    if (isAuthenticated()) {
      const token = getToken()
      const decoded = jwt_decode(token as string) as Decoded
      return decoded?.role
    } return null
  } catch (e) {
    console.log(e)
  }
}

export const isAuthenticated = () => {
  const token = getToken()
  const status = getRefreshStatus()
  return !!(token && !isTokenExpired(token) && status !== RefreshStatus.pending)
}

export const identifyUser = async (token: string) => {
  const user = jwt_decode(token as string) as Decoded
  if (user) {
    await store.dispatch(loginAction(token))
    if (!process.env.REACT_APP_IS_EMBED) {
      await store.dispatch(getUserInfoAsync(token))
    }

    /*
    bootIntercom({
      id: user.userId,
      email: user.email
    })
    */
    Sentry.setUser({ email: user.email })
  }
}

export const onRefresh = async (response: any) => {
  console.log('onRefresh')
  await store.dispatch(loginAction(response.data.exchangeRefreshToken.jwtToken))
  setRefreshToken(response.data.exchangeRefreshToken.refreshToken)
  setToken(response.data.exchangeRefreshToken.jwtToken)
  setRefreshStatus(RefreshStatus.completed)
  setCountOfRestoreAttempts(0)
}

export const onSignUp = async (response: any, context: any = {}) => {
  const token = response.signup.jwtToken
  setRefreshToken(response.signup.refreshToken)
  await identifyUser(token)
  loginRedirect(token, context)
}

export const onLogin = async (response: any, context: any = {}) => {
  const token = response.signin.jwtToken
  setRefreshToken(response.signin.refreshToken)
  await identifyUser(token)
  loginRedirect(token, context)
}

const loginRedirect = (token: string, context: any = {}) => {
  const { history } = context
  const { from } = history.location.state || { from: { pathname: '/' } }

  if (token) {
    history.replace(from)
  }
}

const logoutRedirect = (context: any = {}) => {
  const { history } = context
  const { from } = history?.location?.state || {}

  console.log('logoutRedirect from=', from, history)
  if (from && history) {
    history.replace(from)
  } else {
    window.location.href = '/login'
  }
}

export const logout = (context: any = {}) => {
  console.log('logout')
  // shutIntercom()
  Sentry.configureScope(scope => scope.setUser(null))
  destroyToken()
  store.dispatch(logoutAction())

  logoutRedirect(context)
}

export const getRefreshToken = () => {
  return localStorage.getItem(refreshTokenKey)
}

export const setRefreshToken = (token: string) => {
  return localStorage.setItem(refreshTokenKey, token)
}

export const getToken = () => {
  return localStorage.getItem(loginTokenKey)
}

export const getRefreshStatus = () => {
  return localStorage.getItem(refreshStatusKey)
}

export const setRefreshStatus = (status: string) => {
  return localStorage.setItem(refreshStatusKey, status)
}
export const getCountOfRestoreAttempts = () => {
  // tslint:disable-next-line:radix
  return parseInt(localStorage.getItem(restoreAuntCountKey) || '0')
}

export const setCountOfRestoreAttempts = (count: number = 0) => {
  return localStorage.setItem(restoreAuntCountKey, count.toString())
}

export const incCountOfRestoreAttempts = () => {
  let count = getCountOfRestoreAttempts()
  count = count + 1
  setCountOfRestoreAttempts(count)

  return count
}

export const setLocation = (stripeLocationId?: string) => {
  if (!stripeLocationId) return
  try {
    if ((window as any)?.native?.setStripeLocation && stripeLocationId) {
      (window as any).native.setStripeLocation(
        stripeLocationId,
        process.env.REACT_APP_ENV === 'development'
      )
    }
  } catch (ex) {
    console.log('error', ex)
  }
}

export const setToken = (token: string) => {
  try {
    const native = (window as any).native
    if (native && native.updateSessionToken) {
      native.updateSessionToken(token)
    }
    if (native && native.sendWebVersion) {
      native.sendWebVersion(packageInfo.version, LANG)
    }
  } catch (ex) {
    console.log('error', ex)
  }
  return !isTokenExpired(token) && localStorage.setItem(loginTokenKey, token)
}

export const destroyToken = () => {
  localStorage.removeItem(loginTokenKey)
  localStorage.removeItem(refreshTokenKey)
  localStorage.removeItem(refreshStatusKey)
}

export const getExpiredTime = () => {
  const token = getToken()
  if (!token) {
    return null
  }
  const decoded: any = jwt_decode(token)
  const expiryAt = new Date(((decoded && decoded.exp) || 0) * 1000)
  // const expiryAt = new Date(((decoded && decoded.exp) || 0) * 1000 - 4.5 * 60 * 1000)
  const now = new Date()

  console.log('getExpiredTime expiryAt=', expiryAt)
  console.log('getExpiredTime now=', now)

  const time = expiryAt.getTime() - now.getTime()
  if (time <= 0) return 0
  return time
}

export const isTokenExpired = (input?: string) => {
  const refreshStatus = getRefreshStatus()
  if (refreshStatus === RefreshStatus.pending) {
    return false
  }
  const countOfAttempts = getCountOfRestoreAttempts()
  const token = input || getToken()
  if (!token || countOfAttempts === 5) {
    logout()
    return false
  }
  const decoded: any = jwt_decode(token)
  const expiryAt = new Date(((decoded && decoded.exp) || 0) * 1000)
  // const expiryAt = new Date(((decoded && decoded.exp) || 0) * 1000 - 4.5 * 60 * 1000)
  const now = new Date()

  // console.log('isTokenExpired expiryAt - 5s=', expiryAt)
  // console.log('isTokenExpired now=', now)

  return expiryAt <= now
}

export const restoreAuth = (props?: any) => {
  const { onComplete = console.log } = props || {}

  incCountOfRestoreAttempts()

  const token = getToken()
  if (!isTokenExpired(token || undefined)) {
    return Promise.resolve('!isTokenExpired').then(onComplete)
  }
  // @ts-ignore
  const decoded: any = jwt_decode(token)

  setRefreshStatus(RefreshStatus.pending)

  return getApolloClient({ setError: console.error })
    .mutate({
      mutation: exchangeRefreshToken,
      variables: {
        data: {
          refreshToken: getRefreshToken(),
          userId: decoded.userId
        }
      }
    })
    .then(onRefresh)
    .then(onComplete)
    .catch(logout)
}

function main() {
  const token = getToken()
  const native = (window as any).native

  if (native && token) {
    if (native.updateSessionToken) {
      native.updateSessionToken(token)
    }
    if (native.sendWebVersion) {
      native.sendWebVersion(packageInfo.version, LANG)
    }
  }
  setRefreshStatus('')
  setCountOfRestoreAttempts(0)
}

main()
