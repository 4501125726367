import React from 'react'

import { getExpiredTime, restoreAuth } from 'services/auth'
import { getApolloClient } from 'api/apollo'

const refreshTokenProcess = () => {
  const timeLeft = getExpiredTime()
  console.log('timeLeft=', timeLeft)
  if (timeLeft === null) return

  const timer = setTimeout(() => {
    console.log('end timeout')
    restoreAuth({
      client: getApolloClient({ setError: console.error }),
      onComplete: () => {
        console.log('onComplete')
        clearTimeout(timer)
        refreshTokenProcess()
      }
    })
  }, timeLeft > 2147483647 ? 2147483647 : timeLeft)

  return timer
}

function RefreshToken() {
  React.useEffect(() => {
    const timer = refreshTokenProcess()
    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [])

  return null
}

export default RefreshToken
